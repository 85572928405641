import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    zIndex: 9999,
    background:
      'linear-gradient(45deg,  rgba(107, 192, 88, 0.9) 30%, rgba(71, 184, 91, 0.9) 90%)',
  },
}));

const BackdropGreen = (props) => {
  const classes = useStyles();
  return <div className={classes.root}>{props.children}</div>;
};

export default BackdropGreen;
