import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(45deg, #f2f2f2 30%, #f4f4f4 90%)',
    color: 'white',
    padding: '20px 30px',
  },
  grid: {
    flexGrow: 1,
  },
  paper: {
    width: '50%',
  },
  rightText: {
    fontSize: '1.8rem',
    color: '#333',
    width: '50%',
    lineHeight: '40px',
    textAlign: 'right',
    textTransform: 'uppercase',
    fontWeight: 200,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '2rem',
      lineHeight: '50px',
    },
  },
  logo: {
    maxHeight: 38,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 28,
      marginTop: 5,
    },
    [theme.breakpoints.up('md')]: {
      maxHeight: 38,
    },
    [theme.breakpoints.up('lg')]: {
      maxHeight: 45,
    },
  },
  greenText: {
    //color: '#89bf5f',
    color: '#6bc058',
  },
}));

function Header() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container className={classes.grid} spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid className={classes.paper}>
              <a href='http://greenfamilyguide.com' alt='Green Family Guide'>
                <img
                  src='logo.png'
                  alt='Green Family Guide'
                  className={classes.logo}
                />
              </a>
            </Grid>
            <Grid className={classes.rightText}>
              Business <strong className={classes.greenText}>Directory</strong>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Header;
