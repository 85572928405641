import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Avatar, Button } from '@material-ui/core';
import { Facebook, Language, KeyboardArrowRight } from '@material-ui/icons';
import { deepOrange } from '@material-ui/core/colors';
import { exrept } from '../utils/shortDesc';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    textAlign: 'left',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  top: {
    height: 120,
  },

  large: {
    width: '100%',
    border: '1px solid #ccc',
    padding: 2,
    '& img': {
      maxWidth: '100%',
      maxHeight: 100,
    },
  },

  textH4: {
    wordWrap: 'normal',
    textAlign: 'left',
    //color: '#89bf5f',
    color: '#6bc058',

    fontWeight: 100,
  },
  text: {
    fontSize: '0.9rem',
    textAlign: 'left',
    lineHeight: '1.1rem',
    marginTop: '.5rem',
  },
  iconset: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  fb: {
    color: '#ffffff',
    backgroundColor: '#3b5998',
  },
  call: {
    color: '#ffffff',
    backgroundColor: '#89bf5f',
    //backgroundColor: '#6bc058',
  },
  web: {
    color: '#ffffff',
    backgroundColor: deepOrange[500],
  },
  button: {
    margin: theme.spacing(1.5),
    borderRadius: '50px',
    //background: '#89bf5f',
    background: '#6bc058',
    '&:hover': {
      background: '#47b85b',
    },
  },
}));

function SingleRecord({
  avatar,
  businessName,
  description,
  facebook,
  web,
  selectItem,
  entryItem,
}) {
  const classes = useStyles();

  const setItem = () => {
    selectItem(entryItem);
  };

  return (
    <Grid item xs={12} sm={12} md={4}>
      <Paper className={classes.paper}>
        <Grid container spacing={1} className={classes.top}>
          <Grid item xs={4}>
            <div className={classes.large}>
              <img alt={entryItem['5']} src={entryItem['10']} />
            </div>
          </Grid>
          <Grid item xs={8}>
            <h3 className={classes.textH4}>{entryItem['5']}</h3>
            <p className={classes.text}>{exrept(entryItem['9'], 100)}</p>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className={classes.iconset}>
              {entryItem['14'] ? (
                <a
                  href={entryItem['14']}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Avatar className={classes.fb}>
                    <Facebook />
                  </Avatar>
                </a>
              ) : null}
              {entryItem['6'] ? (
                <a
                  href={entryItem['6']}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Avatar className={classes.web}>
                    <Language />
                  </Avatar>
                </a>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant='contained'
              color='secondary'
              className={classes.button}
              endIcon={<KeyboardArrowRight />}
              onClick={setItem}
            >
              More
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

export default SingleRecord;
