import React from 'react';

import Header from './Components/Layouts/Header';
import Contents from './Components/Layouts/Contents';
import Footer from './Components/Layouts/Footer';

function App() {
  return (
    <div>
      <div id='rootcontent'>
        <Header />
        <Contents />
        <Footer />
      </div>
      <div id='landscape'>
        <img src='landscape.jpg' alt='' />
      </div>
    </div>
  );
}

export default App;
