export const exrept = (str, wordCount) => {
  const tempStr = str.split('').slice(0, wordCount);
  const blankLastIndex = tempStr.lastIndexOf(' ');
  return tempStr.slice(0, blankLastIndex).join('') + '...';
};

export const filteredText = (dataArray, data) => {
  const result = dataArray.includes(data.toLocaleLowerCase());
  //console.log(result);
  return result;
};
//export default { exrept, filteredText };
