import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { Close, Call, Email, Facebook, Web } from '@material-ui/icons';
import { Grid, Avatar, Chip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  popup: {
    width: 800,
    position: 'absolute',
    maxWidth: '80%',
    maxHeight: '90%',
    padding: 20,
    background: '#fff',
    border: '1px solid #89bf5f',
    borderRadius: 15,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'rgba(100, 100, 100, 0.89) 0px 0px 5px',
    color: '#666',
  },
  closeButtonBase: {
    textAlign: 'right',
  },
  closeButton: {
    //background: '#89bf5f',
    background: '#6bc058',
    color: '#fff',
    marginBottom: 10,
    '&:hover': {
      background: '#47b85b',
    },
  },
  imagePlaceHolder: {
    padding: '5px',

    //maxHeight: 150,
    maxWidth: '100%',
    '& img': {
      //height: 150,
      maxWidth: '100%',
      //maxHeight: '100%',
      border: '1px solid rgba(100, 100, 100, 0.2)',
      borderRadius: 15,
      maxHeight: 150,
      // maxWidth: '100%',
    },
  },
  small: {
    width: '20px',
    height: '20px',
    color: '#fff',
    fontSize: '1rem',
  },
  margnbtm: {
    marginBottom: 10,
  },
  textH4: {
    wordWrap: 'normal',
    textAlign: 'left',
    color: '#6bc058',
    fontWeight: 100,
  },
  fb: {
    color: '#ffffff',
    backgroundColor: '#3b5998',
    fontSize: '1rem',
  },
  call: {
    color: '#ffffff',
    backgroundColor: '#6bc058',
    fontSize: '1rem',
  },
  web: {
    color: '#ffffff',
    backgroundColor: 'rgb(256, 10, 74)',
    fontSize: '1rem',
  },
  email: {
    color: '#ffffff',
    backgroundColor: '#ff6600',
    fontSize: '1rem',
  },
  chipLinkContainer: {
    marginTop: '20px',
  },
  chipLink: {
    cursor: 'pointer',
    margin: '10px 5px',
    display: 'inline-block',
  },
  contentScroll: {
    maxHeight: 180,
    minHeight: 150,
    overflow: 'auto',
  },
}));

const Popup = ({ selectedItem, onClick }) => {
  const classes = useStyles();
  return (
    <div className={classes.popup}>
      <div className={classes.closeButtonBase}>
        <IconButton
          aria-label='delete'
          className={classes.closeButton}
          onClick={onClick}
        >
          <Close />
        </IconButton>
      </div>
      <Grid container spacing={3}>
        <Grid item sm={12} md={4}>
          <div className={classes.imagePlaceHolder}>
            <img src={selectedItem['10']} alt='' />
          </div>
        </Grid>
        <Grid item sm={12} md={8}>
          <h1 className={[classes.margnbtm, classes.textH4].join(' ')}>
            {selectedItem['5']}
          </h1>
          <div className={classes.contentScroll}>
            <p> {selectedItem['9']}</p>
          </div>

          <div className={classes.chipLinkContainer}>
            {selectedItem['8'] ? (
              <a
                href={'tel:' + selectedItem['8']}
                alt={selectedItem['5']}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.chipLink}
              >
                <Chip
                  avatar={
                    <Avatar className={[classes.small, classes.call].join(' ')}>
                      <Call className={classes.call} />
                    </Avatar>
                  }
                  label={selectedItem['8']}
                />
              </a>
            ) : null}
            {selectedItem['14'] ? (
              <a
                href={selectedItem['14']}
                alt={selectedItem['5']}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.chipLink}
              >
                <Chip
                  avatar={
                    <Avatar className={[classes.small, classes.fb].join(' ')}>
                      <Facebook className={classes.fb} />
                    </Avatar>
                  }
                  label='Facebook'
                />
              </a>
            ) : null}
            {selectedItem['7'] ? (
              <a
                href={'mailto:' + selectedItem['7']}
                alt={selectedItem['5']}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.chipLink}
              >
                <Chip
                  avatar={
                    <Avatar
                      className={[classes.small, classes.email].join(' ')}
                    >
                      <Email className={classes.email} />
                    </Avatar>
                  }
                  label='Email'
                />
              </a>
            ) : null}
            {selectedItem['6'] ? (
              <a
                href={selectedItem['6']}
                alt={selectedItem['5']}
                target='_blank'
                rel='noopener noreferrer'
                className={classes.chipLink}
              >
                <Chip
                  avatar={
                    <Avatar className={[classes.small, classes.web].join(' ')}>
                      <Web className={classes.web} />
                    </Avatar>
                  }
                  label='Website'
                />
              </a>
            ) : null}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default Popup;
