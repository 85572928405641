import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    position: 'absolute',
    padding: 20,
    background: '#fff',
    border: '1px solid rgb(57, 181, 74)',
    borderRadius: 15,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'rgba(100, 100, 100, 0.89) 0px 0px 5px',
    color: '#ccc',
    zIndex: 99999,
  },
  loader: {
    color: '#47b85b',
  },
}));

function Loader() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress size={80} className={classes.loader} />
      <p>LOADING</p>
    </div>
  );
}

export default Loader;
