import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    //background: 'linear-gradient(120deg, #89bf5f 30%, #74b761 90%)',
    background: 'linear-gradient(120deg, #6bc058 30%, #47b85b 90%)',
    color: 'white',
    padding: '10px 20px',
  },
  textinput: {
    width: '100%',

    color: '#fff',

    '& label.Mui-focused': {
      color: '#fff',
      borderColor: '#fff',
      borderRadius: '50px',
    },

    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '& fieldset': {
        borderColor: '#5e9900',
        color: '#fff',
      },
      '&:hover fieldset': {
        borderColor: 'white',
        color: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#fff',
        color: 'white',
      },
    },
  },

  large: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: '1px solid #ccc',
    padding: 5,
  },
  textH4: {
    wordWrap: 'normal',
    textAlign: 'left',
    //color: '#89bf5f',
    color: '#6bc058',
    fontWeight: 100,
  },
  text: {
    fontSize: '0.8rem',
    textAlign: 'left',
    lineHeight: '1rem',
  },
  iconset: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },

  button: {
    margin: theme.spacing(1.5),
    borderRadius: '50px',
  },
}));

const SearchBar = ({ searchTerm, category, alphabet }) => {
  const [selectedCategory, setCategory] = useState('');
  const [selectedAlpha, setAlpha] = useState('');
  const [term, setTerm] = useState('');
  const classes = useStyles();
  const alphabets = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const handleChange = (e) => {
    setCategory(e.target.value);
    category(e.target.value);
  };
  const handleAlphabets = (e) => {
    setAlpha(e.target.value);
    alphabet(e.target.value);
  };
  const searchChange = (e) => {
    setTerm(e.target.value);
    searchTerm(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Container fixed style={{ padding: '5px 0' }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3}>
            <FormControl
              variant='outlined'
              className={[classes.formControl, classes.textinput].join(' ')}
            >
              <InputLabel id='category'>Category of Listing</InputLabel>
              <Select
                labelId='category'
                id='categoryList'
                value={selectedCategory}
                onChange={handleChange}
                label='Category of Listing'
              >
                <MenuItem value=''>
                  <em>Show All</em>
                </MenuItem>
                <MenuItem value='Earth & Wildlife'>
                  Earth &amp; Wildlife
                </MenuItem>
                <MenuItem value='Home & Garden'>Home &amp; Garden</MenuItem>
                <MenuItem value='Health & Wellbeing'>
                  Health &amp; Wellbeing
                </MenuItem>
                <MenuItem value='Beauty & Fashion'>
                  Beauty &amp; Fashion
                </MenuItem>
                <MenuItem value='Shop Online'>Shop Online</MenuItem>
                <MenuItem value='Work & Learn'>Work &amp; Learn</MenuItem>
                <MenuItem value='Travel & Explore'>
                  Travel &amp; Explore
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <TextField
              id='outlined-basic'
              label='SEARCH'
              variant='outlined'
              value={term}
              onChange={searchChange}
              className={classes.textinput}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <FormControl
              variant='outlined'
              className={[classes.formControl, classes.textinput].join(' ')}
            >
              <InputLabel id='alphabets'>A - Z SEARCH</InputLabel>
              <Select
                labelId='alphabets'
                id='aTozSearch'
                value={selectedAlpha}
                onChange={handleAlphabets}
                label='A - Z SEARCH'
              >
                <MenuItem value=''>
                  <em>All</em>
                </MenuItem>
                {alphabets.map((el) => (
                  <MenuItem value={el} key={el}>
                    {el}
                  </MenuItem>
                ))}
                <MenuItem value='other'>Others</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SearchBar;
