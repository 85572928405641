import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import { Container, Grid } from '@material-ui/core';
import SingleRecord from '../SingleRecord';
import SearchBar from '../SearchBar';
import BackdropGreen from '../BackdropGreen';
import Loader from '../Loader';
import Error from '../Error';
import Popup from '../Popup';
import BannerAd from '../BannerAd';
import { filteredText } from '../../utils/shortDesc';
//import Alphabets from '../Alphabets';

const Contents = () => {
  const [data, setData] = useState([]);
  const [records, setRecords] = useState([]);
  const [category, setCategory] = useState('');
  const [term, setTerm] = useState('');
  const [alpha, setAlpha] = useState('');
  const [err, setErr] = useState('');
  const [current, setCurrent] = useState();
  const excludedWord = [
    'the',
    'a',
    'an',
    'of',
    'on',
    'for',
    'and',
    'our',
    'than',
    'in',
    'is',
    'are',
    'was',
    'were',
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        //console.log('fetch');
        const res = await Axios.get(
          'https://www.greenfamilyguide.com/wp-json/wl/v1/entries'
        );
        setData(res.data);
        setRecords(res.data);
        setErr('');
      } catch (error) {
        console.log(error.message);
        setErr(error.message);
      }
    };
    fetchData();
    //filterData(term);
  }, []);
  //console.log(term);
  // console.log(records);
  const filterSearch = (text) => {
    const searchResultData = data.filter(
      (entry) =>
        (entry['5'].toLowerCase().includes(text.toLowerCase()) ||
          entry['9'].toLowerCase().includes(term.toLowerCase())) &&
        entry['19'].toLowerCase().includes(category.toLowerCase()) &&
        entry['5'].toLowerCase().startsWith(alpha.toLowerCase())
    );
    setRecords(searchResultData);
  };
  const filterCategory = (cat) => {
    const searchResultData = data.filter(
      (entry) =>
        (entry['5'].toLowerCase().includes(term.toLowerCase()) ||
          entry['9'].toLowerCase().includes(term.toLowerCase())) &&
        entry['19'].toLowerCase().includes(cat.toLowerCase()) &&
        entry['5'].toLowerCase().startsWith(alpha.toLowerCase())
    );
    setRecords(searchResultData);
  };

  const filterAlpha = (alphabet) => {
    const searchResultData = data.filter(
      (entry) =>
        (entry['5'].toLowerCase().includes(term.toLowerCase()) ||
          entry['9'].toLowerCase().includes(term.toLowerCase())) &&
        entry['19'].toLowerCase().includes(category.toLowerCase()) &&
        entry['5'].toLowerCase().startsWith(alphabet.toLowerCase())
    );
    setRecords(searchResultData);
  };

  let dispData;

  if (err && data.length <= 0) {
    dispData = (
      <BackdropGreen>
        <Error errorMessage={err} />
      </BackdropGreen>
    );
  } else if (data.length <= 0) {
    dispData = (
      <BackdropGreen>
        <Loader />
      </BackdropGreen>
    );
  } else if (records.length > 0) {
    dispData = records.map((entry) =>
      //if(entry.status==='active' && entry.is_read)
      entry.status === 'active' && entry.is_read === '1' ? (
        <SingleRecord
          key={entry.id}
          /* avatar={entry['10']}
          businessName={entry['5']}
          description={entry['9']}
          facebook={entry['14']}
          web={entry['6']} */
          selectItem={(item) => setCurrentItem(item)}
          entryItem={entry}
        />
      ) : null
    );
  } else {
    dispData = (
      <div
        style={{
          margin: '30px auto',
          textAlign: 'center',
        }}
      >
        <p>No record found!</p>
      </div>
    );
  }

  const handleChange = (text) => {
    //console.log(term);
    if (!filteredText(excludedWord, text.trim())) {
      setTerm(text);
      filterSearch(text);
    }
  };

  const searchCaterory = (cat) => {
    setCategory(cat);
    filterCategory(cat);
  };
  const searchAlpha = (a) => {
    setAlpha(a);
    filterAlpha(a);
  };

  const setCurrentItem = (item) => {
    setCurrent(item);
  };
  const removeCurrentItem = () => {
    setCurrent('');
  };

  //console.log(records, category);

  return (
    <>
      <SearchBar
        searchTerm={handleChange}
        category={(cat) => searchCaterory(cat)}
        alphabet={(a) => searchAlpha(a)}
      />
      <BannerAd />
      <Container fixed style={{ padding: '20px 0', minHeight: '80vh' }}>
        <div style={{ padding: 10 }}>
          <Grid container spacing={1}>
            {dispData}
          </Grid>
        </div>
      </Container>
      {current ? (
        <BackdropGreen>
          <Popup selectedItem={current} onClick={removeCurrentItem} />
        </BackdropGreen>
      ) : null}
    </>
  );
};

export default Contents;
