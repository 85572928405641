import React from 'react';

function BannerAd() {
  return (
    <div style={{ textAlign: 'center' }}>
      <a
        href='http://www.greenfamilyguide.com/list-your-business/'
        alt=''
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src='ad.png' alt='Register Now!' width='300' />
      </a>
    </div>
  );
}

export default BannerAd;
