import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  root: {
    background: 'linear-gradient(45deg, #333 30%, #000 90%)',
    border: 0,

    boxShadow: '0 3px 5px 2px rgba(255, 255, 255, .3)',
    color: '#999',
    padding: '10px',
    textAlign: 'center',
    fontSize: '0.8rem',
  },
});

function Footer() {
  const copyrightDate = new Date();
  const classes = useStyles();
  return (
    <Box component='div' className={classes.root}>
      &copy; {copyrightDate.getFullYear()} Green Family Guide. All Rights
      Reserved.
    </Box>
  );
}

export default Footer;
