import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popup: {
    width: 800,
    position: 'absolute',
    maxWidth: '80%',
    padding: 20,
    background: '#fff',
    border: '1px solid rgb(57, 181, 74)',
    borderRadius: 15,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 'rgba(100, 100, 100, 0.89) 0px 0px 5px',
    color: '#666',
    textAlign: 'center',
  },
}));

const Error = ({ errorMessage }) => {
  const classes = useStyles();
  return (
    <div className={classes.popup}>
      <h1>SOMETHING WENT WRONG!</h1>
      <h2>Please try again.</h2>
      <p>{errorMessage}</p>
    </div>
  );
};

export default Error;
